@use "sass:math";

@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return math.div($number, ($number * 0 + 1));
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: math.div(strip-units($minWidthPx), 16);
	$maxWidth: math.div(strip-units($maxWidthPx), 16);
	$minFontSize: math.div(strip-units($minFontSizePx), 16);
	$maxFontSize: math.div(strip-units($maxFontSizePx), 16);

	$slope: math.div(($maxFontSize - $minFontSize), ($maxWidth - $minWidth));
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--c-brown: #8f8b7f;
	--c-khaki: #c1bba2;

	--t-text-color: var(--c-brown);
	--t-page-bg-color: #fcfbfa;

	--p-static: 14px;

	--p-rhythm: calc(100vw / 24);
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-gutter: min(5vw, 75px);
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);

	@include gen-clamp("--fs-body", 16px, 20px);
	@include gen-clamp("--fs-title", 30px, 45px);
	@include gen-clamp("--fs-subtitle", 20px, 32px);
	@include gen-clamp("--fs-ui-lg", 18px, 23px);
}

@include mixins.from-sm {
	:root {
		--p-static: 20px;
	}
}
