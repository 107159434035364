.btn {
	transition: background 300ms ease-in-out;
	background: var(--c-brown);
	color: white;
	padding: var(--p-static) calc(var(--p-static) * 0.75);
	display: inline-block;
	border-radius: 0;
	&:hover,
	&:focus,
	&:active {
		background: var(--c-khaki);
		cursor: pointer;
	}
}

.btn-group {
}
