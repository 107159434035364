.l-img-flow {
	$c: &;
	display: block;
	&__primary {
	}
	&__secondary {
	}
	&__inset {
	}

	&--complex {
		display: grid;
		grid-template-columns: repeat(44, 1fr);
		position: relative;
		gap: 0;
		#{$c}__primary {
			grid-column: 1 / 31;
		}
		#{$c}__secondary {
			grid-column: 32 / 45;
		}

		&#{$c}--reversed {
			#{$c}__primary {
				grid-row: 1;
				grid-column: 15 / 45;
			}
			#{$c}__secondary {
				grid-row: 1;
				grid-column: 1 / 14;
			}
		}
	}

	&--has-inset {
		#{$c}__primary {
			clip-path: polygon(
				0 0,
				100% 0,
				100% 100%,
				calc(var(--p-rhythm) * 3.5) 100%,
				calc(var(--p-rhythm) * 3.5) calc(100% - (var(--p-rhythm) * 3.5)),
				0% calc(100% - (var(--p-rhythm) * 3.5))
			);
		}

		#{$c}__inset {
			position: absolute;
			bottom: 0;
			left: 0;
			width: calc(var(--p-rhythm) * 3);
			height: calc(var(--p-rhythm) * 3);
		}

		&#{$c}--reversed {
			#{$c}__primary {
				clip-path: polygon(
					0 0,
					100% 0,
					100% calc(100% - (var(--p-rhythm) * 3.5)),
					calc(100% - (var(--p-rhythm) * 3.5))
						calc(100% - (var(--p-rhythm) * 3.5)),
					calc(100% - (var(--p-rhythm) * 3.5)) 100%,
					0 100%
				);
			}

			#{$c}__inset {
				left: auto;
				right: 0;
			}
		}
	}
}
