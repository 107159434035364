@font-face {
	font-family: "montserrat";
	src: url("../fonts/montserrat-normal-400.woff2") format("woff2"),
		url("../fonts/montserrat-normal-400.woff") format("woff"),
		url("../fonts/montserrat-normal-400.ttc") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "montserrat";
	src: url("../fonts/montserrat-italic-400.woff2") format("woff2"),
		url("../fonts/montserrat-italic-400.woff") format("woff"),
		url("../fonts/montserrat-italic-400.ttc") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "montserrat";
	src: url("../fonts/montserrat-normal-500.woff2") format("woff2"),
		url("../fonts/montserrat-normal-500.woff") format("woff"),
		url("../fonts/montserrat-normal-500.ttc") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: "montserrat";
	src: url("../fonts/montserrat-normal-700.woff2") format("woff2"),
		url("../fonts/montserrat-normal-700.woff") format("woff"),
		url("../fonts/montserrat-normal-700.ttc") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "montserrat";
	src: url("../fonts/montserrat-italic-700.woff2") format("woff2"),
		url("../fonts/montserrat-italic-700.woff") format("woff"),
		url("../fonts/montserrat-italic-700.ttc") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}
