@use 'constants';
@use 'mixins';
@use 'global/bases';

.l-home-fold {
	margin: var(--p-rhythm) 0;
	&__intro {
		margin-bottom: var(--p-rhythm-lg);
		margin-top: var(--p-rhythm-lg);
		img {
			width: 80%;
			max-width: 220px;
			margin: 0 auto;
		}
	}
	&__tagline {
		@include bases.base-heading;
		font-size: var(--fs-ui-lg);
		margin-bottom: calc(2 * var(--p-rhythm-lg));
		text-align: center;
		font-style: italic;
		font-weight: normal;
		margin-top: var(--p-rhythm-lg);
		line-height: 1.2;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	&__banner {
		position: relative;
		&-image {
			clip-path: polygon(
				0 0,
				100% 0,
				100% calc(100% - (var(--p-rhythm) * 3.5)),
				calc(100% - (var(--p-rhythm) * 3.5))
					calc(100% - (var(--p-rhythm) * 3.5)),
				calc(100% - (var(--p-rhythm) * 3.5)) 100%,
				0 100%
			);
		}
		&-inset-image {
			position: absolute;
			bottom: 0;
			right: 0;
			width: calc(var(--p-rhythm) * 3);
			height: calc(var(--p-rhythm) * 3);
		}
	}
}

@include mixins.from-sm {
	.l-home-fold {
		display: grid;
		grid-template-columns: repeat(22, 1fr);
		gap: 0;
		margin-top: 0;
		&__intro {
			grid-column: 1 / 9;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			margin-top: 0;
		}
		&__tagline {
			margin-bottom: 0;
			margin-top: var(--p-rhythm);
			max-width: none;
		}
		&__banner {
			grid-column: 10 / 23;
		}
	}
}

@include mixins.from-md {
	.l-home-fold {
		&__intro {
			img {
				width: 100%;
				max-width: none;
			}
		}
	}
}
