.card {
	$c: &;

	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&__image {
		transition: 300ms ease-in-out;
		width: 100%;
		display: block;
	}

	&__details {
		padding-top: var(--p-rhythm-sm);
	}

	&__title {
		font-size: var(--fs-ui-lg);
		transition: 300ms ease-in-out;
	}

	&:hover,
	&:active {
		#{$c}__image {
			filter: brightness(1.05);
		}
		#{$c}__title {
			text-decoration: underline;
		}
	}
}
