@use "constants";
@use "mixins";

.l-site-header {
	$c: &;
	position: relative;
	z-index: 100;

	max-width: var(--page-width);
	margin: var(--p-rhythm) auto;
	&__logo {
		display: block;
		height: 62px;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__menu-trigger {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 3;
	}

	.l-navigation {
		z-index: 2;
	}

	.hide-logo & {
		#{$c}__logo {
			opacity: 0;
			pointer-events: none;
		}
	}
}

@include mixins.from-md {
	.l-site-header {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		margin: 0 auto;

		&__logo {
			width: 167px;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}
