@use "mixins";

.form-field {
	position: relative;
	width: 100%;

	&__field,
	&__label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&__field {
		border: 0 none;
		padding: var(--p-static);
		width: 100%;
		appearance: none;
		border-radius: 0;
		border: 2px solid var(--c-brown);
		color: var(--c-brown);
		background: white;
		&:active,
		&:focus {
			outline: 2px solid var(--c-brown);
		}
	}

	&__boolean-group {
		> * + * {
			margin-top: calc(var(--p-static) / 2);
		}
	}

	&__label {
		margin-bottom: 12px;
		&-required {
			color: red;
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&__help {
		width: 100%;
	}
}

@include mixins.from-md {
	.form-field {
		font-size: 12px;
	}
}
