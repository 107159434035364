.blockquote {
	padding-left: var(--p-rhythm);
	border-left: 2px solid var(--c-khaki);
	margin: 0;
	&__body {
	}
	&__citation {
		margin-top: 0.5em;
		font-style: italic;
	}
}
