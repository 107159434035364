@use "mixins";
@use 'global/bases';

.l-site-footer {
	max-width: var(--page-width);
	margin: var(--p-rhythm-lg) auto var(--p-rhythm) auto;

	&__newsletter {
		&-label {
			@include bases.base-heading;
			font-size: var(--fs-subtitle);
			margin-bottom: var(--p-rhythm-sm);
		}
	}

	&__acknowledgement {
		margin-top: var(--p-rhythm);
	}

	&__colophon {
		margin-top: var(--p-rhythm-lg);
		.logo-list {
			margin-bottom: var(--p-rhythm);
		}

		h3 {
			border-bottom: 2px solid transparent;
			margin-right: 5px;
		}
		> a {
			@include bases.base-link;
			width: auto;
		}
	}

	&__logo {
		display: block;
		height: 32px;
		img {
			width: auto;
			height: 100%;
		}
	}
}

@include mixins.from-md {
	.l-site-footer {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0 var(--p-rhythm);

		&__colophon {
			margin-top: 0;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			.logo-list {
				margin-bottom: 3px;
			}
			h3 {
				margin-left: 20px;
			}
		}
		&__acknowledgement {
			grid-column: 1/3;
		}
	}
}
