.l-signup {
	display: flex;
	flex-wrap: wrap;
	.form-field,
	.btn {
		width: auto;
	}
	.form-field {
		flex: 1;
		margin-right: var(--p-rhythm-sm);
	}
	.btn {
	}
	.form-error:not(:empty) {
		width: 100%;
		margin-top: var(--p-rhythm-sm);
	}
}
