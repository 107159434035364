:root{--c-brown: #8f8b7f;--c-khaki: #c1bba2;--t-text-color: var(--c-brown);--t-page-bg-color: #fcfbfa;--p-static: 14px;--p-static: 0.875rem;--p-rhythm: calc(100vw / 24);--p-rhythm-sm: calc(var(--p-rhythm) / 2);--p-rhythm-lg: calc(var(--p-rhythm) * 2);--page-gutter: min(5vw, 75px);--page-gutter: min(5vw, 4.6875rem);--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);--page-width: min(calc(100vw - var(--page-gutter) * 2), 111.875rem);--fs-body:clamp(
		1rem,
		0.95rem + 0.25vw,
		1.25rem
	);--fs-title:clamp(
		1.875rem,
		1.6875rem + 0.9375vw,
		2.8125rem
	);--fs-subtitle:clamp(
		1.25rem,
		1.1rem + 0.75vw,
		2rem
	);--fs-ui-lg:clamp(
		1.125rem,
		1.0625rem + 0.3125vw,
		1.4375rem
	)}:export{--fs-body_min-width:320;--fs-body_max-width:1920;--fs-body_min-fs:16;--fs-body_max-fs:20}:export{--fs-title_min-width:320;--fs-title_max-width:1920;--fs-title_min-fs:30;--fs-title_max-fs:45}:export{--fs-subtitle_min-width:320;--fs-subtitle_max-width:1920;--fs-subtitle_min-fs:20;--fs-subtitle_max-fs:32}:export{--fs-ui-lg_min-width:320;--fs-ui-lg_max-width:1920;--fs-ui-lg_min-fs:18;--fs-ui-lg_max-fs:23}@media screen and (min-width: 48rem){:root{--p-static: 20px;--p-static: 1.25rem}}