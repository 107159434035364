.logo-list {
	display: flex;
	a {
		display: inline-block;
		opacity: 0.5;
		transition: opacity 0.3s;
		width: auto;
		&:hover {
			opacity: 0.8;
			text-decoration: none;
		}
		img {
			height: 30px;
			width: auto;
			display: block;
		}
	}
	a + a {
		margin-left: 15px;
	}
}
