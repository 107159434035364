@use "mixins";

.l-prose-blocks {
	&__title,
	.rich-text,
	form {
		background: white;
		padding: var(--p-rhythm);
	}

	form {
		> * + * {
			margin-top: var(--p-rhythm);
		}
		.btn {
			width: auto;
			display: inline-block;
		}
	}

	&__title {
		font-size: var(--fs-title);
		margin-top: var(--p-rhythm-lg);
		margin-bottom: 0;
		padding-bottom: var(--p-rhythm-sm);
	}

	> * + * {
		margin-top: var(--p-rhythm-lg);
	}

	.l-prose-blocks__title + .rich-text {
		margin-top: 0;
		padding-top: 0;
	}
}

@include mixins.from-md {
	.l-prose-blocks {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0 var(--p-rhythm);

		&__title {
			margin-top: 3em;
		}

		> * {
			grid-column: 1 / 2;
		}

		.captioned-media,
		.responsive-object {
			grid-column: 1 / 3;
		}

		.captioned-media__restrict-width {
			grid-column: 1 / 2;
		}
	}
}
