@use "mixins";

.l-page {
	max-width: var(--page-width);
	margin: 0 auto;

	&__section + &__section {
		margin-top: var(--p-rhythm);
	}

	&__title {
		font-size: var(--fs-title);
		margin-top: var(--p-rhythm-lg);
		margin-bottom: 0;
		padding-bottom: var(--p-rhythm-sm);
	}

	&__card-list {
		display: grid;
		grid-template-columns: 1fr;
		gap: var(--p-rhythm);
	}
}

@include mixins.from-sm {
	.l-page {
		&__card-list {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
